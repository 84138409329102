import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

const getBgImageByPath = pagePath => {
  const imgProps = {
    alt: '',
    layout: 'fullWidth',
    quality: 100,
  }
  switch (pagePath) {
    case 'shop-all':
    case 'es/shop-all':
    case 'natural-fiber-bags':
    case 'es/natural-fiber-bags':
      return <StaticImage src="./assets/product-category-2.png" {...imgProps} />
    case 'home-and-living':
    case 'es/home-and-living':
      return <StaticImage src="./assets/product-category-3.png" {...imgProps} />
    default:
      return <StaticImage src="./assets/product-category-1.png" {...imgProps} />
  }
}

const CategoryIntro = ({ title, description, pagePath, nav }) => {
  return (
    <>
      {nav && <div className="sm:hidden">{nav}</div>}
      <div className="relative pt-6 sm:pt-0">
        <div className="sm:absolute inset-0 px-6 sm:flex flex-col items-center justify-center text-center z-10">
          <Fade cascade direction="up" duration={400} triggerOnce>
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl 3xl:text-5xl tracking-tight">
              {title}
            </h1>
            <p className="mt-3 mx-auto max-w-sm lg:max-w-lg 3xl:max-w-xl text-xs sm:text-sm 2md:text-base lg:text-lg 3xl:text-xl leading-tight">
              {description}
            </p>
          </Fade>
        </div>
        <div className="hidden sm:block">{getBgImageByPath(pagePath)}</div>
      </div>
      {nav && <div className="hidden sm:block">{nav}</div>}
    </>
  )
}

CategoryIntro.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pagePath: PropTypes.string,
  nav: PropTypes.node,
}

CategoryIntro.defaultProps = {
  title: '',
  description: '',
  pagePath: '',
  nav: null,
}

export default CategoryIntro
