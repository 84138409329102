import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Fade } from 'react-awesome-reveal'

import { scroller } from './SubcategoryNav.module.scss'

const SubcategoryNav = ({ categoryTitle, categoryPagePath, subcategories }) => {
  return (
    <div
      className={`mt-6 sm:mt-1 pb-2 sm:pb-0 flex justify-between items-baseline uppercase text-2xs md:text-xs xl:text-sm text-harvest-gold overflow-x-auto whitespace-no-wrap ${scroller}`}
    >
      <Link to={`/${categoryPagePath}`} activeClassName="border-b">
        {categoryTitle}
      </Link>
      <nav>
        <Fade cascade duration={400} triggerOnce>
          <ul className="flex">
            {subcategories.map(
              ({
                subcategory: {
                  document: {
                    id,
                    data: {
                      title: { text: title },
                    },
                    fields: { pagePath },
                  },
                },
              }) => (
                <li key={id} className="pl-4">
                  <Link to={`/${pagePath}`} activeClassName="border-b">
                    {title}
                  </Link>
                </li>
              )
            )}
          </ul>
        </Fade>
      </nav>
    </div>
  )
}

SubcategoryNav.propTypes = {
  categoryTitle: PropTypes.string.isRequired,
  categoryPagePath: PropTypes.string.isRequired,
  subcategories: PropTypes.array.isRequired,
}

export default SubcategoryNav
